import React from "react";
import * as Icon from "react-feather";
const navigationConfig = [
  {
    id: "graphList",
    title: "All boards",
    type: "item",
    icon: <Icon.Home size={20} />,
    projectEnable: true,
    navLink: "/",
  },
  {
    id: "organizationList",
    title: "Organizations",
    type: "item",
    icon: <Icon.Briefcase size={20} />,
    projectEnable: true,
    navLink: "/org/organization",
  },
  {
    id: "exampleIframe",
    title: "Example Iframe",
    type: "item",
    icon: <Icon.Home size={20} />,
    projectEnable: true,
    navLink: "/org/iframe",
  },
];

export default navigationConfig;
